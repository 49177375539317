
/* Sidebar changed to tab mood */
@media screen and (max-width: 992px) {
  .laptop-sidebar {
    display: none;
  }
  .tab-sidebar {
    display: block;
  }
}

@media screen and (max-width: 764px) {
  .tab-sidebar {
    display: none;
    color: #030303e3;
  }
}

/* Sidebar changed to laptop mood */
@media screen and (min-width: 992px) {
  .laptop-sidebar {
    display: block;
  }
  .tab-sidebar {
    display: none;
  }
}

.sug-follow-user {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}
