table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  max-width: 920px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}
.center-align {
  text-align: center;
}

tr:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.head {
  font-weight: 700;
  font-size: 16px;
  color: black;
}
.price-color {
  color: #278575;
}
.phone-table {
  display: none;
}
.desktop-table {
  background-color: white;
}
@media screen and (max-width: 892px) {
  .phone-table {
    background-color: white;
    /* border: .5px solid lightgray; */
    margin: 6px 0;
    padding: 14px 4px;
    display: flex;
    flex-direction: column;
  }
  .desktop-table {
    display: none;
  }
}
