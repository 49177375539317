html,
body {
  margin: 0;
  padding: 0;
}
.m-nav-container {
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  background: #222;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  position: absolute;
  z-index: 2000;
}
.m-nav-items {
  margin: 12px;
  cursor: pointer;
}
.m-dropdown-container {
  margin-top: 12px;
  background-color: #242526;
  border: 1px solid #474a4d;
  border-radius: 8px;
  transition: height 500ms ease;
}

.nav-item {
  color: lightgrey;
  font-size: 16px;
}
.nav-item:focus {
  font-weight: bold;
  background-color: brown;
  color: white;
  font-size: 16px;
}

.m-close-nav-btn {
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: 0.5px solid lightgrey;
  color: white;
}
.m-close-nav-btn:hover {
  background-color: hsl(0, 0%, 100%, 0.1);
}

.m-close-nav-btn h1 {
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
}
