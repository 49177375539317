.uploadPost-container form {
  width: 100%;
}
.hover-lightgrey:hover {
  background-color: #f4f6f8;
}

.uploadPost-input {
  position: relative;
}

.input-section-title {
  font-weight: 700;
  font-size: 20px;
  color: #1a1b25;
}

.input-section-desc {
  margin: 0;
  color: #1a1b25;
}

.video-drop-zone {
  position: absolute;
}

.upload-btn {
  padding: 0.6rem 1rem;
  background-color: #065fd4;
  border: none;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 2px;
  margin: 15px 0;
}
.upload-btn:hover {
  background-color: #1d76eb;
}

@media screen and (max-width: 475px) {
  .modal-box {
    width: 100%;
  }
}

@media screen and (min-width: 475px) {
  .modal-box {
    width: 475px;
  }
}

@media screen and (min-width: 1100px) {
  .modal-box {
    width: 1000px;
  }

  .uploadPost-input {
    margin-right: 36px;
    margin-left: 26px;
  }
}

#caption:focus-visible {
  outline: none;
}

.grid-layout {
  display: grid;
  grid-column-gap: 6px;
  grid-row-gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  max-width: 674px;
}

.link-upload-site-img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* ----------------- */

.spec-input-div {
  display: flex;
  margin-bottom: 14px;
}
.spec-input-value-div {
  margin: 0 26px;
}
.spec-input-value-div-child {
  display: flex;
  margin-bottom: 5px;
}
.spec-input-value-div-val {
  margin: 0px 0px 0px 6px;
  padding: 4px 6px;
  width: 200px;
  text-align: start;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.link-input-div {
  background-color: white;
  padding: 12px;
}

@media screen and (max-width: 892px) {
  .spec-input-div {
    flex-direction: column;
  }
  .spec-input-value-div {
    margin: 0;
  }
  /* .spec-input-value-div-val {
    margin: 0;
  } */
  .link-input-div {
    background-color: transparent;
    padding: 0;
  }
}
