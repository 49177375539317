.mobile-visible-only {
  display: none;
}
.laptop-visible-only {
  display: none;
}
@media screen and (max-width: 892px) {
  .home-side-bar {
    display: none !important;
  }
  .mobile-visible-only {
    display: block !important;
  }
  .laptop-visible-only {
    display: none !important;
  }
  .body-container {
    margin: auto;
  }
}

@media screen and (min-width: 892px) {
  .home-side-bar {
    display: block !important;
  }
  .mobile-visible-only {
    display: none !important;
  }
  .laptop-visible-only {
    display: block !important;
  }
}
